import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyLaod from "react-lazyload"
import MapSection from "../sections/About/MapSection"
import Check from "../images/Check.png"
import {
  SectionPage,
  SectionTitle,
  SectionDescription,
  BreadCrumb,
  LeftPannel,
  RightPannel,
  MarkdownContent,
  Container,
  ExploreMore,
} from "../components/Section"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import CallToAction from "../components/CallToAction"
import PrimaryButton from "../components/Button/PrimaryButton"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import { graphql, Link } from "gatsby"
import QuickContactForm from "../components/QuickContactForm"
import CardSection from "../sections/Home/CardSection"


const ListCity = styled.ul`
  list-style: none;
  margin: 0 0 30px 20px;
  padding: 0;
  column-count: 2;
  column-fill: balance;
  justify-content: center;
  @media (min-width: 768px) {
    column-count: 3;
  }
  @media (min-width: 992px) {
    column-count: 4;
  }
  @media (min-width: 1200px) {
    column-count: 5;
  }
`
const ListItemCity = styled.li`
  vertical-align: text-top;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #1e4156;
  margin: 0 0 10px;
  padding: 0;
  &:before {
    content: url(${Check});
    display: inline-block;
    margin-left: -20px;
    width: 20px;
    vertical-align: top;
  }
  a {
    color: #000;
    font-weight: 700;
    line-height: 26px;
    display: inline-block;
    &:hover {
      color: #fc0002;
    }
  }
`



const StateLandingTemplate = ({ data, location }) => {
  const AllProducts = data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )
  const stateLandingData = data.contentfulStateLanding

  return (
    <Layout location={location}>
      <SEO
        title={stateLandingData.metaTitle}
        description={stateLandingData.metaDescription}
      />
      <HeroSection>
        <Container>
          <BreadCrumb className="static">
            <Link to="/">Home</Link> /{" "}
            <span> {stateLandingData.identifyName}</span>
          </BreadCrumb>
          <HeroCover>
            <LeftHero>
              <HeroPageTitle>{stateLandingData.heroTitle}</HeroPageTitle>
              <HeroDesc
                dangerouslySetInnerHTML={{
                  __html:
                    stateLandingData.heroDescription.childMarkdownRemark.html,
                }}
              />
            </LeftHero>
            <RightHero>
              <HeroFormWrap>
                <HeroFormTitle>Get In Touch</HeroFormTitle>
                <HeroFormBody>
                  <QuickContactForm
                    location={location}
                    formName="Florida Steel Building Contact Form"
                  />
                </HeroFormBody>
              </HeroFormWrap>
            </RightHero>
          </HeroCover>
        </Container>
      </HeroSection>

      <LazyLaod>
        <CardSection
          data={{
            title: "Top-Selling Buildings in Florida",
            description: {},
          }}
          productData={AllProducts}
        />
      </LazyLaod>

      <MapSection
        stateName={stateLandingData.stateName}
        mapImage={stateLandingData.mapImage}
        cities={stateLandingData.cities}
      />

      {/* <SectionPage
				ept="80px"
				epb="80px"
				xpt="40px"
				xpb="40px"
				pt="30px"
				pb="30px"
				bgBefore="#fff"
			>
				<Container>
					<SectionTitle>Areas We Serve In <strong>Floria</strong></SectionTitle>
					<SectionDescription textAlign="center"><p>We cover all locations throughout the state of <strong>Floria</strong>, including:</p></SectionDescription>
					<ListCity>
						{['Jacksonville','Tallahassee','Pensacola','Fort Lauderdale','St. Petersburg','Orlando','Port St. Lucie','Tampa','Hialeah'].map((item, index) =>
							<ListItemCity key={index}>{item}</ListItemCity>
						)}
					</ListCity>
					<ExploreMore><button type='button' className="btn-lg"><PrimaryButton text="Load More" /></button></ExploreMore>
				</Container>
			</SectionPage> */}

      <SectionPage
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bg="#F4FBFF"
      >
        <Container>
          <LeftPannel>
            <MarkdownContent
              dangerouslySetInnerHTML={{
                __html: stateLandingData.contents.childMarkdownRemark.html,
              }}
            />
          </LeftPannel>
          <RightPannel>
            <DesignPlan />
            <AccreditedBusiness />
          </RightPannel>
        </Container>
      </SectionPage>

      <CallToAction />
    </Layout>
  )
}

export default StateLandingTemplate

export const pageQuery = graphql`
  query StateLandingPageQuery($id: String!) {
    contentfulStateLanding(id: { eq: $id }) {
      stateName
      abbreviation
      identifyName
      url
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      mapImage {
        fluid(maxWidth: 200, quality: 100) {
          base64
          sizes
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      cities {
        cityName
        url
      }
      contents {
        childMarkdownRemark {
          html
        }
      }
    }
    cities: allContentfulCityLanding {
      nodes {
        url
        cityName
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
